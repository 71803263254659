const getPalette = (theme) => {
  return {
    type: "light",
    primary: { main: "#5F0038", hover: "#B10068" },
    secondary: { main: "#375f00", hover: "#66B100" },
    accent: "#5F0038",
    info: {
      main: "#5F0038",
    },
    error: {
      main: "#FF0040",
    },
    white: {
      main: "#FFF",
    },
  };
};

export default getPalette;
