import React from "react";
import Typography from "@mui/material/Typography";
import { menuImages, restaurantImages } from "./gallery";
import { getSwiperImages } from "../../utils/swiper";
import {
  getMerchantAccolades,
  ACCOLADE_FOOD_HYGIENE,
  ACCOLADE_HALAL,
  ACCOLADE_TRIP_ADVISOR_TOP_RATED,
} from "../../utils/merchant";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Our coffee is amazing.
      </Typography>
      <Typography variant="body1" paragraph>
        I know that everyone says their coffee is the best, but everyone else is
        wrong. Our coffee is the best.
      </Typography>
      <Typography variant="body1" paragraph>
        Imagine the best cup of coffee you've ever had, and then imagine if it
        could be three times as good. That coffee you just imagined doesn't even
        come close to how good our coffee is.
      </Typography>
      <Typography variant="body1" paragraph>
        I could make some empty promise about a money-back guarantee if you're
        not satisfied with our coffee, but it would be a waste of time.
        Everybody loves our coffee.
      </Typography>
      <Typography variant="body1">
        Do yourself a favour, come and get a cup &nbsp;
        <span role="img" aria-label="coffee">
          &#9749;
        </span>
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return (
    <>
      <Typography variant="body1" paragraph>
        All of our coffee is fair trade. It's delicious and also ethical, so if
        you're not drinking it then you need to ask yourself why.
      </Typography>

      <Typography variant="body1">
        We suggest that you come and get a cup of our coffee immediately.
      </Typography>
    </>
  );
}

export function getAccolades() {
  return getMerchantAccolades([
    ACCOLADE_FOOD_HYGIENE,
    ACCOLADE_HALAL,
    ACCOLADE_TRIP_ADVISOR_TOP_RATED,
  ]);
}

export function getGalleryMenuImages() {
  return getSwiperImages(menuImages);
}

export function getGalleryRestaurantImages() {
  return getSwiperImages(restaurantImages);
}
