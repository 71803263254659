export const menuImages = [
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/coffee_corner/menu/english_breakfast_tea.jpg",
    alt: "english breakfast tea",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/coffee_corner/menu/caffee_late.jpg",
    alt: "caffee late",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/coffee_corner/menu/classic_iced_cappuccino.jpg",
    alt: "classic iced cappuccino",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/coffee_corner/menu/caramel_macchiato.jpg",
    alt: "caramel macchiato",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/coffee_corner/menu/caffee_mocha.jpg",
    alt: "caffee mocha",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/coffee_corner/menu/cappuccino_freddo.jpg",
    alt: "cappuccino freddo",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/coffee_corner/menu/caffee_americano.jpg",
    alt: "caffee americano",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/coffee_corner/menu/cappuccino.jpg",
    alt: "cappuccino",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/coffee_corner/menu/cortado.jpg",
    alt: "cortado",
  },
];

export const restaurantImages = [
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/coffee_corner/restaurant/4.jpg",
    alt: "4",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/coffee_corner/restaurant/5.jpg",
    alt: "5",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/coffee_corner/restaurant/2.jpg",
    alt: "2",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/coffee_corner/restaurant/3.jpg",
    alt: "3",
  },
  {
    src: "https://my-orders.ams3.digitaloceanspaces.com/coffee_corner/restaurant/1.jpg",
    alt: "1",
  },
];
